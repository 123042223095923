

function parse(html) {
    const template = document.createElement('template');
    if ('content' in template) {
        template.innerHTML = html;
        return document.importNode(template.content, true);
    }
    const frag = document.createDocumentFragment();
    const div = document.createElement('div');
    div.innerHTML = html;
    while (div.firstChild) {
        frag.appendChild(div.firstChild);
    }
    return frag;
}

function escape(str) {
  var div = document.createElement('div')
  div.appendChild(document.createTextNode(str))
  return div.innerHTML.replace(/"/g,'&quot;').replace(/'/g,'&#39;')
}

function empty(node) {
	while (node.hasChildNodes()) node.removeChild(node.lastChild);
}

export default {
	parse,
	escape,
    empty
}